@import url('./css/root.css');

@font-face {
  font-family: "Rubik";
  src: url('./assets/font/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  /* normal */
}

@font-face {
  font-family: "Rubik";
  src: url('./assets/font/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  /* bold */
}

/* CONSTRUCTION PAGE */
.construction {

  row-gap: 1rem;

}

.construction h1 {

  font-weight: bold;

}

.construction img {

  width: 25%;

}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  top: 0;
  position: relative;
  font-family: "Rubik" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {

  background-color: transparent;
  width: 10px;

}

body::-webkit-scrollbar-thumb {

  background-color: var(--blue);

}

/* BUTTONS */
.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--blue), inset 0.3em 0.3em 0 0 var(--blue);
}

.offset:hover,
.offset:focus {
  box-shadow: 0 0 0 0 var(--blue), inset 6em 3.5em 0 0 white;
}

/* NAVBAR */
nav {

  position: fixed !important;
  top: 0px;
  min-height: 120px;
  width: 100%;
  z-index: 999;

}

@media(min-width: 992px) {

  nav {

    padding: 1rem 10px !important;

  }

}

nav a {

  color: white !important;
  cursor: pointer;
  text-decoration: none;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

}

nav img {

  width: 200px;

}

@media(max-width: 375px) {

  nav .navbar-brand img {

    width: 170px;

  }

  .navbar-brand {

    padding-left: 0px !important;

  }

}

.menu-icon {

  width: 32px;
  height: 32px;

}

@media(min-width: 992px) {

  .menu-icon {

    display: none;

  }

}

/* MENU MOBILE */
.menu-mobile {

  visibility: hidden;
  background-color: #eae4e3;
  left: 0;
  top: 120px;
  min-width: 200px;
  width: 40%;
  height: 0;
  opacity: 0;
  transition: .5s;

}

.menu-mobile.visible {

  height: calc(100vh - 119px);
  opacity: 1;
  visibility: visible;

}

.menu-mobile ul {

  list-style: none;
  margin: 0;
  padding: 0 1rem;

}

.menu-mobile ul a {

  background: rgb(39, 105, 170);
  background: linear-gradient(90deg, rgba(39, 105, 170, 1) 0%, rgb(234, 228, 227) 0%, rgb(234, 228, 227) 100%);
  border-bottom: 2px solid var(--blue);
  color: black !important;
  padding: 1rem 2rem;

}

.menu-mobile ul a:hover {

  background: rgb(39, 105, 170);
  color: white !important;
  animation: hover ease-in-out .1s;

}

@keyframes hover {

  0% {

    background: linear-gradient(90deg, rgba(39, 105, 170, 1) 0%, rgb(234, 228, 227) 0%, rgb(234, 228, 227) 100%);

  }

  25% {

    background: linear-gradient(90deg, rgba(39, 105, 170, 1) 25%, rgb(234, 228, 227) 25%, rgb(234, 228, 227) 100%);

  }

  50% {

    background: linear-gradient(90deg, rgba(39, 105, 170, 1) 50%, rgb(234, 228, 227) 50%, rgb(234, 228, 227) 100%);

  }

  75% {

    background: linear-gradient(90deg, rgba(39, 105, 170, 1) 75%, rgb(234, 228, 227) 75%, rgb(234, 228, 227) 100%);

  }

  100% {

    background: linear-gradient(90deg, rgba(39, 105, 170, 1) 100%, rgb(234, 228, 227) 100%, rgb(234, 228, 227) 100%);

  }

}

/* HEADER */
header {

  background-image: url('./assets/media/personas-dandose-la-mano.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 450px;
  color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 1rem 2rem;

}
header::before {

  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);

}

header form > div > div {

  margin-bottom: 1rem;

}

header form h3 {

  font-size: clamp(1.5rem, 2rem, 3rem);
  font-weight: bold;
  display: block;

}

header form input {

  padding: .35rem .75rem;
  width: 60%;

}
@media(max-width: 768px) {

  header form input {

    padding: .35rem .75rem;
    width: 80%;
  
  }

  header form button {

    left: calc(80% - 3rem) !important;

  }

}

header form > div > div span {

  font-size: clamp(12px,  1vw, 18px) !important;

}

header form button {

  height: 100%;
  background-color: var(--blue);
  width: 3rem;
  left: calc(60% - 3rem);
  top: 0;
  bottom: 0;
  border: 0;
  padding: .35rem .75rem;

}

header form button img {

  width: 100%;

}

header img {

  width: 400px;

}

@media (max-width: 768px) {

  header img {

    width: 300px;

  }

}

@media (max-width: 635px) {

  header>div>img {

    display: none;

  }

  .header-text {

    width: 100%;
    flex: none !important;

  }

  .header-text img {

    display: block;

  }

}

.header-text {

  animation-name: rotation;
  animation-duration: .5s;
  animation-direction: normal;
  position: relative;

}

@keyframes rotation {

  from {

    transform: rotateY(0deg);

  }

  to {

    transform: rotateY(360deg);

  }

}

.header-text>img {

  /*background-color: var(--blue);
  padding: 2rem 1rem;*/
  box-shadow: 2px 10px 22px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 10px 22px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 22px 2px rgba(0, 0, 0, 0.75);

}

/* FOOTER */
footer {

  color: white;
  min-height: 300px;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 1;

}

@media(min-width: 1200px) {

  footer {

    position: fixed;

  }

}

.container-social-footer>div {

  position: relative;

}

.container-social-footer a {

  border: 2px solid white;
  border-radius: 1rem;
  padding: 1rem;

}
.container-social-footer a:hover {

  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);

}

.container-social-footer a img {

  width: 32px;

}

footer .copyright {

  position: absolute;
  bottom: 0%;
  text-align: center;
  width: 100%;

}

footer span {

  font-size: clamp(.7rem, .5vw, 1rem);

}

/* LOADER */
.loader {
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 999999;
}

.mini-loader {
  position: absolute;
  background-color: white;
  height: 100% !important;
  width: 100%;
  z-index: 999999;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: '';
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #076fe5;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 var(--blue);
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px var(--blue);
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 var(--blue);
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px var(--blue);
    height: 40px;
  }
}

/* TITLES */


/* BACKGROUNDS */
.bg-blue {

  background-color: var(--blue);

}

.bg-blue-gradient {

  background: rgb(51, 204, 204);
  background: linear-gradient(90deg, rgba(51, 204, 204, 1) 0%, rgba(45, 152, 186, 1) 57%, rgba(39, 105, 170, 1) 100%);

}

/* CONTENT */
.content {

  min-height: 100vh;
  background-color: white;
  padding-top: 120px;
  position: relative;
  z-index: 2;
  margin-bottom: 300px;

}

@media(min-width: 1200px) {

  .content {

    margin-bottom: 300px;

  }

}

/* SERVICES CARDS */
.container-services {

  view-timeline: --scroll;

}

.service-card {

  background-color: rgb(234, 228, 227);
  display: flex;
  align-items: center;
  justify-content: center;

}
.service-card.home-card {

  background-image: url('./assets/media/home-card.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.service-card.car-card {

  background-image: url('./assets/media/car-card.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.service-card.health-card {

  background-image: url('./assets/media/health-card.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.service-card.fire-card {

  background-image: url('./assets/media/fire-card.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.service-card.more-card {

  background-image: url('./assets/media/more-card.png');
  background-size: cover;
  background-repeat: no-repeat;

}

.service-card button {

  align-self: center;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: black;
  font-weight: bolder;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 2rem;
  width: 100%;

}

.service-card button:hover>span {

  display: none;

}

.service-card button:hover>img {

  margin-right: 0px;

}

.service-card img {

  margin-right: 1rem;
  width: 30px;

}

/* BORDERS */
.divisor-line {

  display: block;
  border-top: 2px solid var(--blue);
  margin: -15% auto;
  width: 30%;

}

@media(max-width: 991px) {

  .divisor-line {

    margin: .5rem auto;

  }

}

.divisor-line-normal {

  display: block;
  border-top: 2px solid var(--blue);
  margin: 1rem auto;
  width: 10%;
  min-width: 100px;

}

.divisor-line-white {

  display: block;
  border-top: 2px solid white;
  margin: 1rem auto;
  width: 10%;
  min-width: 80px;

}

.border-y-blue {

  border-top: 2px solid var(--blue);
  border-bottom: 2px solid var(--blue);

}

.border-bottom-blue {

  border-bottom: 2px solid var(--blue);

}

/* PROTECTION TEXT */
.container-protection-text {

  background-image: url('./assets/media/image-1.webp');
  background-repeat: no-repeat;
  background-position: center;

}

.protection-text {

  animation: beat 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;

}
@media(max-width: 325px) {

  .protection-text {

    animation: none;

  }
  .protection-text>div {

    width: 100%;

  }
  .protection-text > div h3 {

    font-size: 1rem !important;

  }

}

@keyframes beat {

  from {

    transform: scale(1);

  }

  to {

    transform: scale(1.05);

  }

}

.protection-text>div {

  color: white;
  padding: 2rem 3rem;
  -webkit-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);

}

.background {

  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("./assets/media/proteccion.jpg");
  background-size: cover;
  background-position: center center;

}

/* ABOUT US INDEX */
.container-aboutus-text {

  background-color: rgb(234, 228, 227);
  padding: 2rem 3rem;
  -webkit-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);

}

.container-aboutus-text a {

  color: var(--blue);
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;

}

@media(max-width: 992px) {

  .container-aboutus-text img {

    object-fit: none;
    object-position: center;
    max-height: 400px;
    width: 100%;

  }

}

/* NEWS */
.news-section {

  animation: auto linear animate-in;
  animation-duration: auto;
  animation-timeline: view();
  animation-range: entry 0% entry 100%;

}

.news-section article {

  background-color: rgb(234, 228, 227);
  box-shadow: 5px 8px 22px -5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 8px 22px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 22px -5px rgba(0, 0, 0, 0.75);

}

@keyframes animate-in {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

}

.see-more {

  width: auto;

}

.fade-bottom {
  overflow: hidden;
  position: relative;
}

.fade-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, 1));
  transition: bottom 0.5s ease-in-out;
}

.fade-bottom:hover:before {
  bottom: -50px;
}

.news-section a {

  display: block;
  text-decoration: none;
  left: 50%;
  top: 90%;
  transform: translateX(-50%);

}

.news-section h4 {

  padding: 1rem;
  width: 100%;
  margin-bottom: 0;
  color: white;
  font-weight: bolder;

}

.loading {

  animation: loading 1.5s ease-in-out infinite;

}

@keyframes loading {

  from {

    opacity: .4;

  }

  to {

    opacity: 1;

  }

}

.bg-article {

  object-fit: cover;
  max-height: 200px;
  width: 100%;

}

.new-content {

  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
  max-height: 110px;

}

.new-content>div {

  max-width: 100%;

}

.ql-container.ql-snow {

  border: none !important;

}

/* SOCIAL BAR */
.social-bar {

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0;

}

.social-bar-media {

  display: flex;
  justify-content: center;
  column-gap: 2rem;

}

/* TO UP BUTTON */
.to-up-button {

  border-radius: 50%;
  border: 2px solid var(--blue);
  -webkit-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  transition-duration: .5s;
  opacity: 0;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem;
  z-index: 9999;

}

/* PARTNERS CARROUSEL */
.partners-carrousel-container {

  background-color: white;
  width: 100%;

}

.partners-carrousel {

  position: relative;
  overflow: hidden;
  max-width: 1080px;

}

.partners-carrousel::before {

  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(45, 152, 186, 0) 100%);

}

.partners-carrousel::after {

  right: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(45, 152, 186, 0) 100%);
  top: 0;

}

.partners-carrousel::before,
.partners-carrousel::after {

  content: '';
  position: absolute;
  height: 86px;
  width: 15rem;
  z-index: 2;

}

.partners-carrousel>div {

  animation: 15s linear 0s infinite normal none running slider;
  display: flex;
  width: calc(300px * 3);
  column-gap: 1rem;
  transition: all .5s ease-in-out;

}

@keyframes slider {

  from {

    transform: translateX(0px);

  }

  to {

    transform: translateX(calc(-100px*3));

  }

}

.partners-carrousel-item::before,
.partners-carrousel-item::after {

  content: '';
  flex: 0 0 auto;
  width: 100%;
  box-sizing: border-box;

}

.partners-carrousel::before {

  margin-right: auto;

}

.partners-carrousel::after {

  margin-left: auto;

}

.partners-carrousel img {

  width: 200px;
  align-items: center;

}

/* TESTIMONIAL CARROUSEL */
.testimonial-carrousel-container {

  width: 100%;

}

.testimonial-carrousel {

  position: relative;
  overflow: hidden;

}

.testimonial-carrousel::before {

  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(45, 152, 186, 0) 100%);

}

.testimonial-carrousel::after {

  right: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(45, 152, 186, 0) 100%);
  top: 0;

}

.testimonial-carrousel::before,
.testimonial-carrousel::after {

  content: '';
  position: absolute;
  height: 100%;
  width: 2rem;
  z-index: 2;

}

.testimonial-carrousel>div {

  animation: 15s linear 0s infinite normal none running slider-testimonial;
  display: flex;
  transition: all 1s ease-in-out;
  width: 100%;

}

@keyframes slider-testimonial {

  0% {

    margin-left: 0%;

  }

  30% {

    margin-left: 0%;

  }

  33% {

    margin-left: -100%;

  }

  63% {

    margin-left: -100%;

  }

  66% {

    margin-left: -200%;

  }

  95% {

    margin-left: -200%;

  }

  97% {

    margin-left: -0;

  }

}

.testimonial-carrousel-item {

  color: white;
  padding: 1.5rem;
  flex: 0 0 auto;
  width: 100%;

}

.testimonial-carrousel-item>div {

  min-height: 166px;
  -webkit-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 3px 10px 22px 0px rgba(0, 0, 0, 0.49);

}

.testimonial-carrousel-item img {

  width: 64px;

}

.testimonial-carrousel-item p {

  font-style: italic;
  font-size: .8rem;

}

/* MODAL BASE */
.container-modal, .services-modal {

  align-items: center;
  background-color: rgba(0, 0, 0, 0.49);
  display: none;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;

}

.modal-content {

  background-color: white;
  border-left: 12px outset var(--blue);
  border-right: 12px outset var(--blue);
  padding: 1rem 1.5rem;
  position: relative;
  width: 60%;

}

.services-modal .modal-content {

  width: 70%;

}

.services-modal .position-sticky {

  top: 0;
  z-index: 9999;

}

@media(max-width: 768px) {

  .modal-content {

    width: 80% !important;

  }

}

@media(max-width: 375px) {

  .modal-content {

    width: 90% !important;

  }

}

.modal-content>button {

  background-color: transparent;
  border: none;
  position: absolute;
  top: -40px;
  right: 0;

}

/* MAIL MODAL FORM */
.mail-modal-form>div {

  position: relative;
  margin-bottom: 1.5rem;

}

.mail-modal-form input+label {

  position: absolute;
  padding-left: .5rem;
  top: 50%;
  transition: .3s ease-in-out;
  transform: translate(0%, -50%);

}

.mail-modal-form input[type="text"]:focus+label,
.mail-modal-form input[type="text"]:user-valid+label,
.mail-modal-form input[type="email"]:focus+label,
.mail-modal-form input[type="email"]:user-valid+label,
.mail-modal-form textarea:focus+label,
.mail-modal-form textarea:user-valid+label, 
.mail-modal-form input[type='text']:not(:placeholder-shown) + label,
.mail-modal-form input[type='email']:not(:placeholder-shown) + label{

  font-weight: bold;
  padding: 0 .5rem 0 0;
  background-color: white;
  top: 0%;

}

.mail-modal-form input[type="checkbox"] + label > strong {

  font-size: clamp(10px, 1vw, 20px);

}

.mail-modal-form textarea+label {

  position: absolute;
  padding-left: .5rem;
  top: 25%;
  transition: .3s ease-in-out;
  transform: translate(0%, -50%);

}

.mail-modal-form input,
.mail-modal-form textarea {

  border: 2px outset var(--blue);
  padding: .75rem .5rem;

}

.mail-modal-form button {

  border: none;
  color: white;
  display: flex;
  align-items: center;
  column-gap: .3rem;
  padding: .3rem .5rem;

}

.mail-modal-form p {

  position: absolute;

}

/* MAP MODAL */
#map {

  height: 400px;

}

#map iframe {

  width: 100%;
  height: 100%;

}

/* TELEPHONE MODAL */
.telephone-modal-content>div div {

  margin-bottom: 1.5rem;

}

.telephone-modal-content li {

  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 1.5rem;

}

.telephone-modal-content button.bg-danger {

  background-color: transparent;
  border: 0;
  position: relative;
  padding: .3rem .5rem;

}
.telephone-modal-content button:focus > span {

  visibility: visible;
  opacity: 1;

}

.telephone-modal-content button span {

  visibility: hidden;
  background-color: rgb(19, 213, 25);
  color: white;
  padding: .4rem;
  position: absolute;
  opacity: 0;
  transition: 1.5s ease-in-out;
  left: 70%;
  bottom: 125%;
  font-size: clamp(.6rem, .8rem, 1rem);
  z-index: 9999;

}
.telephone-modal-content button span::after {

  content: '';
  position: absolute;
  left: 0px;
  bottom: -5px;
  transform: rotateX(45deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(19, 213, 25)

}

.telephone-modal-content li>span {

  text-decoration: underline;
  font-size: clamp(.8rem, .5vw, 1rem);

}

.telephone-modal-content h5 {

  font-weight: bolder;

}

/* SERVICES MODAL */
.services-modal .modal-content {

  margin-top: 10%;
  overflow-y: auto;
  max-height: 450px;

}

.services-modal .modal-content::-webkit-scrollbar {

  background-color: white;
  width: .5rem;

}

.services-modal .modal-content::-webkit-scrollbar-thumb {

  background-color: var(--blue);

}

.service-icon {

  background-color: var(--blue);
  box-shadow: -1px 1px 7px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 7px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 7px 1px rgba(0, 0, 0, 0.75);
  padding: 1rem;
  position: absolute;
  right: 1rem;
  top: -1rem;

}
@media(min-width: 768px) {

  .service-icon img {

    width: 36px !important;

  }

}
@media(max-width: 420px) {

  .service-icon {

    display: none;

  }

}
.service-icon img {

  width: 24px;

}

.plans-modal-container {

  gap: 1rem 10px;
  justify-content: space-evenly;

}

.plan-container > button {

  background-color: var(--blue);
  border: 0;
  color: white;
  font-size: clamp(1rem, .5vw, 1.2rem);
  font-weight: bolder;
  padding: .75rem 1rem;
  text-align: left;
  width: 100%;

}

.plan-container > button > img {

  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0%, -50%);

}
@media(max-width: 767px) {

  .plan-container > button > img {

    position: relative;
    transform: none;
    right: 0;

  }
  .plan-container > button {

    display: flex;
    justify-content: space-between;
    align-items: center;

  }

}

.plan-description {

  color: black;
  background-color: rgb(234, 228, 227);
  visibility: hidden;
  height: 1px;
  transition: all ease;
  transition-duration: .5s;
  padding: .01rem .01rem;
  opacity: 0;

}
.plan-description p {
  
  text-align: left !important;

}
button.visible + div.plan-description {

  height: 100%;
  transition: ease-in-out;
  transition-duration: .5s;
  opacity: 1;
  visibility: visible;
  padding: .75rem 1rem;
  border-bottom: 2px solid black;

}
button.visible img {

  transform: rotateX(180deg) translate(0%, 50%);

}

.plan-description button {

  background-color: transparent;
  border: 2px solid var(--blue);
  color: var(--blue);
  font-size: clamp(.85rem, .5vw, 1rem);
  transition: ease-in-out .5s;
  padding: .15rem 1rem;

}
.plan-description button:hover {

  background-color: var(--blue);
  color: white;

}

/* RESULT TOOLTIP */
.tooltip-result {

  position: fixed;
  color: white;
  visibility: hidden;
  bottom: 2rem;
  left: 2rem;
  padding: .5rem 1rem;
  z-index: 99999999;
  max-width: 300px;
  font-style: italic;

}

/* SERVICE REQUEST */
.service-request-form select {

  height: 100%;
  border: 2px outset var(--blue);
  padding: 1rem .5rem;
  border-radius: 0.375rem;

}
@media(max-width: 425px) {

  .service-request-form select {

    padding-right: .25rem !important;
    font-size: .75rem;

  }

}

.service-request-form input[name='phone'] + label {

  right: .5rem;

}

/* NEWS */
.new-content-header img {

  overflow: hidden;
  object-fit: cover;
  height: 300px;
  width: 100%;

}

.new-category {

  color: var(--blue);
  margin-top: 1.5rem;
  font-style: italic;

}

.new-container .new-body {

  font-size: medium;
  margin-top: .5rem;
  padding-bottom: 2.5rem;

}

.new-date {

  font-size: small;
  font-style: italic;
  padding-right: .5rem;

}

.new-container .subscription-text button {

  border: 0;
  background-color: transparent;
  color: var(--blue);
  text-decoration: underline;
  padding-bottom: 2rem;

}

.ql-container {

  height: auto !important;

}

/* LATESTS NEWS */
.latest-news {

  margin-top: 2rem;
  position: sticky;
  top: 150px;
  height: 100%;

}
@media (max-width: 991px) {

  .latest-news {

    position: relative;
    top: 0;

  }

}

.latest-news h4 {

  padding: 1rem .5rem;
  color: white;

}

.latest-news ul {

  border: 1px solid var(--blue);
  list-style: none;
  padding: 0rem .5rem;

}

.latest-news li {

  padding: .75rem 0rem;
  font-weight: bold;
  border-bottom: 1px solid var(--blue);

}

.latest-news li:last-of-type {

  border-bottom: 0;

}

/* BLOG */
.blog-content section ul {

  list-style: none;
  padding-left: 0;

}

.blog-content section ul a {

  color: white;
  font-weight: bolder;
  z-index: 9999;

}

.blog-content section ul li {

  padding: 1.25rem .75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: end;
  height: 200px;

}

.blog-content section ul li::after {

  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  height: 100%;
  width: 100%;

}

.blog-content section ul li span {

  color: white;
  font-style: italic;
  font-size: clamp(.5rem, .75rem, 1rem);
  padding: 0rem .5rem;
  z-index: 9999;

}

.blog-content section ul li img {
  
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -999;

}

/* BACK BUTTON */
.back-button a {

  text-decoration: none;
  display: flex;
  align-items: center;

}

.back-button img {

  margin-right: 10px;
  width: 1rem;

}